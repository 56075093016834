<template>
  <div id='app'>
    <file-pond
      name='file'
      ref='pond'
      label-idle="Drop files here or <span class='filepond--label-action'>Browse</span><br><span>File must be either PDF, PNG or JPEG/JPG</span>"
      :allow-multiple="multiple"
      accepted-file-types='image/jpeg, image/png, application/pdf'
      v-on:init='handleFilePondInit'
      v-on:processfile='handleProcessFile'
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond, {setOptions} from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

setOptions({
  server: {
    url: process.env.VUE_APP_ROOT_API,
    process: '/upload/image-upload'
    // revert: '/upload/delete'
    /* load: (source, load, error, progress, abort, headers) => {
      var myRequest = new Request(source)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    } */
  }
})
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
  name: 'UploadWidget',
  data: function () {
    return {
      // myFiles: ['cat.jpeg'],
      file_url: '',
      value: ''
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleFilePondInit: function () {
      this.$refs.pond.removeFile()
      // console.log(this.file_url)
      // console.log(this.$refs.pond.getFiles())
    },
    handleProcessFile: function (error, file) {
      if (error) {
        // console.log(error)
      }
      /* // console.log(file.serverId)
      this.file_url = file.serverId
      // console.log(this.file_url) */
      this.value = file.serverId
      this.$emit('fileUpdated', file.serverId)
    },
    removeFiles () {
      this.$refs.pond.removeFile()
    },
    getUrl () {
      return this.value
    }
  },
  components: {
    FilePond
  }
}
</script>
