import {parse, format} from 'date-fns'

const countryList = [
  {
    name: 'United States',
    abbreviation: 'US'
  },
  {
    name: 'Canada',
    abbreviation: 'CA'
  },
  {
    name: 'Australia',
    abbreviation: 'AU'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  }
]
const countryStateList = {
  US: [
    {
      name: 'Alabama',
      abbreviation: 'AL'
    },
    {
      name: 'Alaska',
      abbreviation: 'AK'
    },
    {
      name: 'Arizona',
      abbreviation: 'AZ'
    },
    {
      name: 'Arkansas',
      abbreviation: 'AR'
    },
    {
      name: 'California',
      abbreviation: 'CA'
    },
    {
      name: 'Colorado',
      abbreviation: 'CO'
    },
    {
      name: 'Connecticut',
      abbreviation: 'CT'
    },
    {
      name: 'Delaware',
      abbreviation: 'DE'
    },
    {
      name: 'Florida',
      abbreviation: 'FL'
    },
    {
      name: 'Georgia',
      abbreviation: 'GA'
    },
    {
      name: 'Hawaii',
      abbreviation: 'HI'
    },
    {
      name: 'Idaho',
      abbreviation: 'ID'
    },
    {
      name: 'Illinois',
      abbreviation: 'IL'
    },
    {
      name: 'Indiana',
      abbreviation: 'IN'
    },
    {
      name: 'Iowa',
      abbreviation: 'IA'
    },
    {
      name: 'Kansas',
      abbreviation: 'KS'
    },
    {
      name: 'Kentucky',
      abbreviation: 'KY'
    },
    {
      name: 'Louisiana',
      abbreviation: 'LA'
    },
    {
      name: 'Maine',
      abbreviation: 'ME'
    },
    {
      name: 'Maryland',
      abbreviation: 'MD'
    },
    {
      name: 'Massachusetts',
      abbreviation: 'MA'
    },
    {
      name: 'Michigan',
      abbreviation: 'MI'
    },
    {
      name: 'Minnesota',
      abbreviation: 'MN'
    },
    {
      name: 'Mississippi',
      abbreviation: 'MS'
    },
    {
      name: 'Missouri',
      abbreviation: 'MO'
    },
    {
      name: 'Montana',
      abbreviation: 'MT'
    },
    {
      name: 'Nebraska',
      abbreviation: 'NE'
    },
    {
      name: 'Nevada',
      abbreviation: 'NV'
    },
    {
      name: 'New Hampshire',
      abbreviation: 'NH'
    },
    {
      name: 'New Jersey',
      abbreviation: 'NJ'
    },
    {
      name: 'New Mexico',
      abbreviation: 'NM'
    },
    {
      name: 'New York',
      abbreviation: 'NY'
    },
    {
      name: 'North Carolina',
      abbreviation: 'NC'
    },
    {
      name: 'North Dakota',
      abbreviation: 'ND'
    },
    {
      name: 'Ohio',
      abbreviation: 'OH'
    },
    {
      name: 'Oklahoma',
      abbreviation: 'OK'
    },
    {
      name: 'Oregon',
      abbreviation: 'OR'
    },
    {
      name: 'Pennsylvania',
      abbreviation: 'PA'
    },
    {
      name: 'Puerto Rico',
      abbreviation: 'PR'
    },
    {
      name: 'Rhode Island',
      abbreviation: 'RI'
    },
    {
      name: 'South Carolina',
      abbreviation: 'SC'
    },
    {
      name: 'South Dakota',
      abbreviation: 'SD'
    },
    {
      name: 'Tennessee',
      abbreviation: 'TN'
    },
    {
      name: 'Texas',
      abbreviation: 'TX'
    },
    {
      name: 'Utah',
      abbreviation: 'UT'
    },
    {
      name: 'Vermont',
      abbreviation: 'VT'
    },
    {
      name: 'Virginia',
      abbreviation: 'VA'
    },
    {
      name: 'Washington',
      abbreviation: 'WA'
    },
    {
      name: 'West Virginia',
      abbreviation: 'WV'
    },
    {
      name: 'Wisconsin',
      abbreviation: 'WI'
    },
    {
      name: 'Wyoming',
      abbreviation: 'WY'
    }
  ],
  CA: [
    {
      name: 'Alberta',
      abbreviation: 'Alberta'
    },
    {
      name: 'British Columbia',
      abbreviation: 'British Columbia'
    },
    {
      name: 'Manitoba',
      abbreviation: 'Manitoba'
    },
    {
      name: 'New Brunswick',
      abbreviation: 'New Brunswick'
    },
    {
      name: 'Newfoundland and Labrador',
      abbreviation: 'Newfoundland and Labrador'
    },
    {
      name: 'Nova Scotia',
      abbreviation: 'Nova Scotia'
    },
    {
      name: 'Ontario',
      abbreviation: 'Ontario'
    },
    {
      name: 'Prince Edward Island',
      abbreviation: 'Prince Edward Island'
    },
    {
      name: 'Quebec',
      abbreviation: 'Quebec'
    },
    {
      name: 'Saskatchewan',
      abbreviation: 'Saskatchewan'
    }
  ],
  AU: [
    {
      name: 'Australian Capital Territory',
      abbreviation: 'ACT'
    },
    {
      name: 'New South Wales',
      abbreviation: 'NSW'
    },
    {
      name: 'Northern Territory',
      abbreviation: 'NT'
    },
    {
      name: 'Queensland',
      abbreviation: 'QLD'
    },
    {
      name: 'South Australia',
      abbreviation: 'SA'
    },
    {
      name: 'Tasmania',
      abbreviation: 'TAS'
    },
    {
      name: 'Victoria',
      abbreviation: 'VIC'
    },
    {
      name: 'Western Australia',
      abbreviation: 'WA-AU'
    }
  ]
}
const allStateList = Object.values(countryStateList).reduce((acc, val) => acc.concat(val), []).sort((a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
})
const getStateList = function (countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }
  return countryStateList[countryCode]
}
const formatDate = function (date, countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }

  if (countryCode === 'AU') {
    return format(parse(date), 'DD/MM/YY')
  } else {
    return format(parse(date), 'MM/DD/YY')
  }
}

const formatDateFullYear = function (date, countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }
  if (countryCode === 'AU') {
    return format(parse(date), 'DD/MM/YYYY')
  } else {
    return format(parse(date), 'MM/DD/YYYY')
  }
}

const formatDateTime = function (date, countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }

  if (countryCode === 'AU') {
    return format(parse(date), 'DD/MM/YYYY, h:mm a')
  } else {
    return format(parse(date), 'MM/DD/YYYY, h:mm a')
  }
}

const getDateFormat = function (countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }

  if (countryCode === 'AU') {
    return 'DD/MM/YYYY'
  } else {
    return 'MM/DD/YYYY'
  }
}
const EXCHANGE_RATE = {
  AU: 1.54,
  CA: 1.40,
  US: 1
}
const getCurrencyInfo = function (countryCode) {
  if (!countryCode) {
    countryCode = 'US'
  }

  if (countryCode === 'AU') {
    return {
      symbol: 'A$',
      currency: 'aud',
      exchangeRate: EXCHANGE_RATE.AU
    }
  } else if (countryCode === 'CA') {
    return {
      symbol: 'C$',
      currency: 'cad',
      exchangeRate: EXCHANGE_RATE.CA
    }
  } else {
    return {
      symbol: '$',
      currency: 'usd',
      exchangeRate: EXCHANGE_RATE.US
    }
  }
}

export default {
  countryList,
  countryStateList,
  getStateList,
  allStateList,
  formatDate,
  formatDateFullYear,
  formatDateTime,
  getDateFormat,
  getCurrencyInfo
}
